import Button from '@restart/ui/esm/Button';
import React, { useEffect, useRef, useState } from 'react';
import { Card, CardGroup, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { BankBuilding, BankCard, BtcEthCoin, InfoIcon, PerformanceIcon, PerformancePortfolioMobileIcon } from '../../../assets/images';
import { InvestmentHistory, InvestmentResponseType } from '../../../models/api/dashboard-model';
import { ClientDashboardRoutes } from '../../../routes/routes';
import { formatCurrency } from '../../../utils/utils';
import clientService from './../../../service/clientService';
import CheckoutButton from './components/checkout-button';
import InvestAnnualStatistic from './components/invest-annual-statistics';
import InvestHistory from './components/invest-history';
import ReinvestReferral from './components/reinvest-referral';
const priceList = [100, 300, 500, 1000, 2000, 5000, 10000];

const Invest: React.FC = () => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const [amount, setAmount] = useState<number>(0);
    const [cryptoAmount, setCryptoAmount] = useState<number>(0);
    const [investmentInfo, setInvestmentInfo] = useState<InvestmentResponseType>();
    const [investmentHistory, setInvestmentHistory] = useState<InvestmentHistory[]>([]);

    const getInvestment = () => {
        clientService.getInvestment().then((res) => {
            setInvestmentInfo(res.data);
        });
    };

    const getInvestmentHistory = () => {
        clientService.getInvestmentHistory().then((res) => {
            setInvestmentHistory(res.data.history);
        });
    };

    useEffect(() => {
        getInvestment();
        getInvestmentHistory();
    }, []);

    const updateData = () => {
        getInvestment();
        getInvestmentHistory();
    };

    const coppyWallet = () => {
        navigator.clipboard.writeText('');
        addToast(t('toast.cryptoWalletCoppied'));
    };

    const cryptoInvest = () => {
        clientService
            .buyWithCrypto({ amount: priceList[cryptoAmount], redirectUrl: `${process.env.REACT_APP_API_URL}${ClientDashboardRoutes.INVEST}` })
            .then(({ data }) => {
                window.location.href = data.data.hosted_url;
            });
    };

    const investDisclaimer = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {t('invest.investDisclaimer')}
        </Tooltip>
    );

    return (
        <div className="invest-page-container">
            <div>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={investDisclaimer}>
                    <Button className="bg-transparent border-0">
                        <img src={InfoIcon} className="info-icon-size" alt="" />
                    </Button>
                </OverlayTrigger>
            </div>
            <div className="deposit-widget">
                <Row>
                    <Col xs={12} lg={4}>
                        <div className="primary-text">{t('invest.totalDeposit')}</div>
                        <div className="secondary-text">{formatCurrency(investmentInfo?.allInvested)}</div>
                    </Col>
                    <Col xs={12} lg={4} className="last-deposit">
                        <div className="primary-text">{t('invest.lastDeposit')}</div>
                        <div className="secondary-text">{formatCurrency(investmentInfo?.lastInvested)}</div>
                    </Col>
                    <Col xs={12} lg={4}>
                        <div className="primary-text">{t('invest.info')}</div>
                        <div className="info-text">{t('invest.infoText')}</div>
                    </Col>
                </Row>
            </div>

            <Row className="invest-widgets-grid-container d-flex">
                {/* <InvestChart /> */}
                <Col xs={12} lg="9">
                    <div className="invest-chart-container">
                        {/* {t('invest.portfolioPerformanceChart')} <br />
                        {t('invest.comingSoon')} */}
                        <img src={PerformanceIcon} alt="" className="portfolio-performance-image" />
                        <img src={PerformancePortfolioMobileIcon} alt="" className="portfolio-performance-image-mobile" />
                    </div>
                </Col>
                <Col xs={12} lg="3">
                    {investmentInfo?.annualInvestment && <InvestAnnualStatistic annual={investmentInfo.annualInvestment} />}
                    <InvestHistory investmentHistory={investmentHistory} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <ReinvestReferral handleReinvest={updateData} />
                </Col>
            </Row>
        </div>
    );
};

export default Invest;
