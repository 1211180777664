import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import './assets/css/style.scss';
import { useUser } from './context/user-context';
import { useLocalStorage } from './hooks/use-local-storage';
import { TOKEN } from './models/const';
import { RolesEnum } from './models/enums';
import Admin from './pages/admin/admin';
import Client from './pages/client/client';
import Employee from './pages/employee/employee';
import LandingPage from './pages/landing-page/landing-page';
import RoleRoute from './routes/guard/role-route';
import Routes, { ROUTES } from './routes/routes';
import clientService from './service/clientService';

function App(): JSX.Element {
    const history = useHistory();
    const { user, setUser } = useUser();
    const { value: token } = useLocalStorage<string>(TOKEN, '');
    const { value: path, setValue: setPath, clear: clearPath } = useLocalStorage<string>('route', '');
    if (!path && ROUTES.includes(history.location.pathname)) {
        setPath(history.location.pathname);
    }

    useEffect(() => {
        if (token) {
            clientService.getUser().then(
                (response) => {
                    setUser(response.data.user);
                },
                () => {
                    localStorage.clear();
                },
            );
        }
    }, [token, history, setUser]);

    useEffect(() => {
        if (token) {
            if (!!path) {
                history.replace(path);
                clearPath();
                return history.replace(path);
            }
        }
        if (user) {
            if (user.roles.includes(RolesEnum.ADMIN)) {
                return history.push(Routes.ADMIN);
            }
            if (user.roles.includes(RolesEnum.EMPLOYEE)) return history.push(Routes.EMPLOYEE);
            if (user.roles.includes(RolesEnum.USER)) {
                return history.push(Routes.USER);
            }
        }
    }, [user, history, token, path, clearPath]);

    return (
        <Switch>
            <RoleRoute role={RolesEnum.USER} path={Routes.USER} component={Client} />
            <RoleRoute role={RolesEnum.ADMIN} path={Routes.ADMIN} component={Admin} />
            <RoleRoute role={RolesEnum.EMPLOYEE} path={Routes.EMPLOYEE} component={Employee} />

            <Route path={Routes.ROOT} component={LandingPage} />
        </Switch>
    );
}

export default App;
