import { ApproveIcon, DeclinedIcon, WaitingIcon } from '../../../../assets/images';
import { IdentityDataStatusEnum } from '../../../../models/enums';
import React, { useEffect, useState } from 'react';
import clientService from './../../../../service/clientService';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';

import { ProfileResponseType, ProfileType } from '../../../../models/api/auth-models';

const icons: { [key: string]: string } = {
    APPROVED: ApproveIcon,
    DECLINED: DeclinedIcon,
    PENDING: WaitingIcon,
};
type PropsType = {
    data?: ProfileType;
};

const ProfileId: React.FC<PropsType> = ({ data }) => {
    const history = useHistory();

    const routeChange = () => {
        const path = data?.linkToKyc;
        if (path) {
            history.push('https://stackoverflow.com/questions/15786129/converting-java-objects-to-json-with-jackson');
        }
    };
    return (
        <div className="profile-document-wrapper">
            <div>
                <img src={DeclinedIcon} className="info-icon-size" />
            </div>
            <div className="profile-info">
                <div className="primary-text">Identity Verification</div>
                <div className="secondary-text">
                    You must verify your identity. Please follow this link to secure website and provide proof of address.
                </div>
            </div>
            <div></div>
            <div className="btn-container">
                {
                    <a className="btn-border" href={data?.linkToKyc} target="_blank" rel="noreferrer">
                        Verify
                    </a>
                }
            </div>
        </div>
    );
};

export default ProfileId;
