import React, { useContext, useState, useEffect } from 'react';
import { InboxAlertType, ProfileType } from '../models/api/auth-models';
import { Children } from '../models/models';
import clientService from '../service/clientService';
import adminService from '../service/adminService';
import { useRoles } from '../hooks/use-roles';
import { RolesEnum } from '../models/enums';

type NotificationProviderPropsType = {
    children: Children;
};

const NotificationContextDefaultValue = {
    notificationCount: 0,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setNotificationCount: (count: number) => {},
    withdrawalRequestCount: 0,
    inboxAlerts: [] as InboxAlertType[],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    loadAlerts: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    loadCount: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    loadWithdrawalRequests: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    loadProfile: () => {},
    profile: undefined as ProfileType | undefined,
};

const NotificationContext = React.createContext(NotificationContextDefaultValue);
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useNotification = () => {
    return useContext(NotificationContext);
};

export const NotificationProvider: React.FC<NotificationProviderPropsType> = ({ children }) => {
    const [notificationCount, setNotificationCount] = useState<number>(0);
    const [inboxAlerts, setInboxAlerts] = useState<InboxAlertType[]>([]);
    const [withdrawalRequestCount, setWithdrawalRequestCount] = useState<number>(0);
    const [profile, SetProfile] = useState<ProfileType>();

    const { hasRole } = useRoles();

    const loadCount = () => {
        clientService.getInbox({}, undefined, undefined, false).then((res) => {
            setNotificationCount(res.data.unreadCount);
        });
    };

    const loadAlerts = () => {
        clientService.getInboxAlert().then((res) => {
            setInboxAlerts(res.data.alerts);
        });
    };

    const loadProfile = () => {
        clientService.getProfile().then((identityResponse) => {
            SetProfile(identityResponse.data.identityDataDTO);
        });
    };

    const loadWithdrawalRequests = () => {
        adminService.getWithdrawCount().then(({ data }) => {
            setWithdrawalRequestCount(data);
        });
    };

    useEffect(() => {
        if (hasRole(RolesEnum.USER)) {
            loadCount();
            loadAlerts();
            loadProfile();
        }
        if (hasRole(RolesEnum.ADMIN)) {
            loadWithdrawalRequests();
        }
    }, [hasRole]);

    return (
        <NotificationContext.Provider
            value={{
                notificationCount,
                withdrawalRequestCount,
                inboxAlerts,
                loadWithdrawalRequests,
                setNotificationCount,
                loadCount,
                loadAlerts,
                loadProfile,
                profile,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};
