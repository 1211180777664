import { AxiosError, AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { useUser } from '../../../context/user-context';
import { LoginDataType, LoginResponseType } from '../../../models/api/auth-models';
import { RouteModalPropsType } from '../../../models/models';
import clientService from '../../../service/clientService';
import { useLocalStorage } from './../../../hooks/use-local-storage';
import authService from './../../../service/authService';
import LoginView from './login-view';
import { useNotification } from '../../../context/notification-context';
import { RolesEnum } from '../../../models/enums';

const Login: React.FC<RouteModalPropsType> = ({ isOpened, onClose }) => {
    const { loadCount, loadAlerts, loadWithdrawalRequests, loadProfile } = useNotification();

    const {
        register,
        handleSubmit,
        formState: { errors },
        trigger,
    } = useForm<LoginDataType>();

    const { setUser } = useUser();

    const { addToast } = useToasts();

    const { setValue: setToken, clearStore } = useLocalStorage<string>('TOKEN', '');
    const [loading, setLoading] = useState<boolean>(false);

    const handleLogin = async (data: LoginDataType) => {
        setLoading(true);
        authService.login(data).then(onSucces, onError);

        function onSucces(response: AxiosResponse<LoginResponseType>) {
            setToken(response.data.jwt);
            clientService.getUser().then(
                (response) => {
                    setUser(response.data.user);
                    if (!!response.data.user?.roles?.includes(RolesEnum.ADMIN)) {
                        loadWithdrawalRequests();
                    }
                    if (!!response.data.user?.roles?.includes(RolesEnum.USER)) {
                        loadCount();
                        loadAlerts();
                        loadProfile();
                    }
                },
                () => {
                    clearStore();
                },
            );
        }

        function onError(error: AxiosError) {
            setLoading(false);
            addToast(error?.response?.data.message, { appearance: 'error' });
        }
    };

    return (
        <>
            <LoginView
                isOpened={isOpened}
                handleLogin={handleSubmit(handleLogin)}
                handleClose={onClose}
                register={register}
                errors={errors}
                trigger={trigger}
                loading={loading}
            />
        </>
    );
};

export default Login;
