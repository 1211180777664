import React, { useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
import SignOut from '../../../src/assets/images/sign-out.svg';
import {
    HamburgerCollapse,
    HamburgerToggle,
    IconMenu,
    IconPiechart,
    IconPiechartActive,
    IconUser,
    IconUserActive,
    InboxActiveIcon,
    InboxIcon,
} from '../../assets/images';
import { useUser } from '../../context/user-context';
import { IdentityDataStatusEnum, InboxTypeEnum, RolesEnum } from '../../models/enums';
import { SidebarLinkItem } from '../../models/models';
import Routes, { AdminRoutes, ClientDashboardRoutes, EmployeeRoutes } from '../../routes/routes';
import { generateShortUserName, inboxColors } from '../../utils/utils';
import { useRoles } from './../../hooks/use-roles';
import UserLogo from './../user-logo';
import NavLinkItem from './nav-link-item';
import { useNotification } from '../../context/notification-context';

type PorpsType = {
    links: SidebarLinkItem[];
};

const BaseSidebar: React.FC<PorpsType> = ({ links }) => {
    const { user, setUser } = useUser();
    const { notificationCount, profile } = useNotification();
    const history = useHistory();
    const { hasRole } = useRoles();
    const { t } = useTranslation();

    const logout = () => {
        localStorage.clear();
        setUser(undefined);
        history.replace(Routes.ROOT);
    };

    const profileMatch = useRouteMatch({
        path: ClientDashboardRoutes.PROFILE,
    });

    const inboxMatch = useRouteMatch({
        path: ClientDashboardRoutes.INBOX,
    });

    const dashboardPath = () => {
        if (hasRole(RolesEnum.USER)) return ClientDashboardRoutes.DASHBOARD;
        if (hasRole(RolesEnum.ADMIN)) return AdminRoutes.DASHBOARD;
        if (hasRole(RolesEnum.EMPLOYEE)) return EmployeeRoutes.DASHBOARD;
        return Routes.ROOT;
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <nav className="navbar navbar-expand navbar-dark flex-md-column flex-row align-items-start p-0 width-sidebar">
            <div className="collapse navbar-collapse align-items-start hamburger-spacing">
                <div className="main-sidebar">
                    <div className="sidebar-content">
                        <div className="sidebar-menu-icon">
                            <img src={IconMenu} alt="" />
                        </div>
                        <div className="sidebar-item-list">
                            {hasRole(RolesEnum.USER) && (
                                <NavLink to={ClientDashboardRoutes.PROFILE} exact activeClassName="active" className="sidebar-item">
                                    <img className="custom-icon" src={profileMatch ? IconUserActive : IconUser} alt="" />
                                    {profile?.status !== IdentityDataStatusEnum.APPROVED && <div className="inbox-badge"></div>}
                                </NavLink>
                            )}
                            <NavLink to={dashboardPath} activeClassName={profileMatch || inboxMatch ? '' : 'active'} className="sidebar-item">
                                <img src={profileMatch || inboxMatch ? IconPiechart : IconPiechartActive} alt="" />
                            </NavLink>
                            {hasRole(RolesEnum.USER) && user?.isInvested && (
                                <NavLink to={ClientDashboardRoutes.INBOX} exact activeClassName="active" className="sidebar-item">
                                    <img src={inboxMatch ? InboxActiveIcon : InboxIcon} alt="" />
                                    {notificationCount > 0 && <div className="inbox-badge"></div>}
                                </NavLink>
                            )}
                        </div>
                    </div>
                </div>
                <ul className="flex-md-column flex-row navbar-nav w-100 justify-content-between">
                    <li className="nav-item">
                        <Button onClick={handleShow} className="btn-mobile-active">
                            <img src={HamburgerToggle} alt="" />
                        </Button>

                        <Offcanvas show={show} className="offcanvas-bg">
                            <Offcanvas.Header className="justify-content-end">
                                <img src={HamburgerCollapse} alt="" onClick={handleClose} />
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <div className="main-navbar">
                                    <div className="sidebar-profile">
                                        <div className="profile-img">
                                            <UserLogo name={generateShortUserName(user?.name, user?.surname)} />
                                        </div>
                                        <div className="profile-name">{`${user?.name} ${user?.surname}`}</div>
                                        <div className="profile-locality"></div>
                                        <button className="base-btn logout-btn" onClick={logout}>
                                            <span>Log out </span>
                                            <img className="logout-icon" src={SignOut} alt="" />
                                        </button>
                                        <div className="divider" />
                                    </div>

                                    <ul className="nav flex-column" onClick={handleClose}>
                                        {profileMatch ? (
                                            <li className="nav-item">
                                                <NavLink to={ClientDashboardRoutes.PROFILE} exact activeClassName="active" className="nav-link">
                                                    {t('navlinks.profile')}
                                                </NavLink>
                                            </li>
                                        ) : (
                                            links.map((link, index) => <NavLinkItem key={index} item={link} />)
                                        )}

                                        <>
                                            {user?.isInvested && (
                                                <li className="nav-item">
                                                    <NavLink to={ClientDashboardRoutes.INBOX} exact activeClassName="active" className="nav-link">
                                                        {t('navlinks.iboxManager')}
                                                    </NavLink>
                                                </li>
                                            )}
                                        </>
                                    </ul>
                                </div>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </li>
                    <div className="main-navbar hide-main-navbar">
                        <div className="sidebar-profile">
                            <div className="profile-img">
                                <UserLogo name={generateShortUserName(user?.name, user?.surname)} />
                            </div>
                            <div className="profile-name">{`${user?.name} ${user?.surname}`}</div>
                            <div className="profile-locality"></div>
                            <button className="base-btn logout-btn" onClick={logout}>
                                <span>Log out </span>
                                <img className="logout-icon" src={SignOut} alt="" />
                            </button>
                            <div className="divider" />
                        </div>

                        <ul className="nav flex-column">
                            {inboxMatch && (
                                <>
                                    <li className="nav-item mt-4">
                                        <NavLink to={ClientDashboardRoutes.INBOX} exact activeClassName="active" className="nav-item-header">
                                            {t('navlinks.iboxManager')}
                                        </NavLink>
                                    </li>

                                    <div className="labels-content">
                                        <div className="nav-item-header">Labels</div>
                                        <div className="label-list">
                                            {Object.values(InboxTypeEnum).map((item) => (
                                                <div className="label-item" key={item}>
                                                    <div className="label-point" style={{ backgroundColor: inboxColors[item] }}></div>
                                                    <div className="label-title">{t(`enums.inboxType.${item}`)}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )}
                            {!inboxMatch && links.map((link, index) => <NavLinkItem key={index} item={link} />)}
                        </ul>
                    </div>
                </ul>
            </div>
        </nav>
    );
};

export default BaseSidebar;
